import React from 'react';

import './footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {
  faInstagramSquare,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { FooterData } from '../../models/footer';
import { Information } from '../../models/information';

export const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  const data: { strapiFooter: FooterData; strapiInformation: Information } =
    useStaticQuery(graphql`
      query {
        strapiFooter {
          linkGroups {
            ... on STRAPI__COMPONENT_OTHER_GROUPE_DE_LIEN {
              name
              links {
                link
                text
              }
            }
          }
        }
        strapiInformation {
          instagramLink
          linkedinLink
        }
      }
    `);

  const footer = data.strapiFooter;
  const infos = data.strapiInformation;

  return (
    <footer className="section footer">
      <div className="section__content">
        <div className="footer__main-row">
          <div className="footer__contact">
            <div className="section__title footer__contact__title">
              Vous avez une question ?
            </div>
            <Link to="/contact/" className="tat-button">
              Contactez-nous
              <FontAwesomeIcon
                icon={faArrowRight}
                className="tat-button__icon"
              />
            </Link>
          </div>
          <nav className="footer__nav">
            {footer.linkGroups.map((group, index) => (
              <div className="footer__nav__cat" key={index}>
                <div className="footer__nav__cat__name">{group.name}</div>
                <div className="footer__nav__cat__links">
                  {group.links.map((link, index) => (
                    <Link
                      to={link.link}
                      className="footer__nav__cat__link"
                      key={index}
                    >
                      {link.text}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </nav>
        </div>
        <div className="footer__second-row">
          <div className="footer__company-name">
            <span className="footer__company-name__terraterre">
              <span className="footer__company-name__terra">Terra</span>
              <span className="footer__company-name__terre">terre</span>
            </span>
            <span className="footer__company-name__copyright">
              {' '}
              &copy; {currentYear}
            </span>
          </div>
          <div className="footer__legal">
            <Link to="/mentions-legales" className="footer__legal__link">
              Mentions légales
            </Link>
            <Link to="/cgv" className="footer__legal__link">
              CGV
            </Link>
          </div>
          <div className="footer__social">
            Suivez-nous sur
            <a
              href={infos.instagramLink}
              className="footer__social__link"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                className="footer__social__link__icon"
                icon={faInstagramSquare}
              />
            </a>
            <a
              href={infos.linkedinLink}
              className="footer__social__link"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                className="footer__social__link__icon"
                icon={faLinkedin}
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
