import React, { AnimationEvent } from 'react';
import { LoaderLogoBar } from './loader-logo-bar';

import './loader.scss';

export const Loader: React.FC = () => {
  const [domLoaded, setDomLoaded] = React.useState(false);
  const [animationEnd, setAnimationEnd] = React.useState(false);

  React.useEffect(() => {
    const handleDomLoaded = () => {
      if (document.readyState === 'complete') {
        setDomLoaded(true);
      }
    };

    handleDomLoaded();
    document.addEventListener('readystatechange', handleDomLoaded);

    return () => {
      document.removeEventListener('readystatechange', handleDomLoaded);
    };
  }, []);

  const handleAnimationEnd = (event: AnimationEvent<SVGSVGElement>) => {
    if (event.animationName === 'rotate-scale') {
      setAnimationEnd(true);
    }
  };

  return (
    <div className={'loader' + (animationEnd ? ' loader--fade-out' : '')}>
      {domLoaded && (
        <div className="loader__logo">
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            className="loader__logo__shape"
            onAnimationEnd={handleAnimationEnd}
          >
            <defs>
              <linearGradient id="grad1" x1="0%" x2="0%" y1="0%" y2="100%">
                <stop offset="0%" stopColor="#72C5BD" stopOpacity="1" />
                <stop offset="100%" stopColor="#72C5BD" stopOpacity="0" />
              </linearGradient>
              <linearGradient id="grad2" x1="0%" x2="0%" y1="0%" y2="100%">
                <stop offset="0%" stopColor="#fff" stopOpacity="0" />
                <stop offset="100%" stopColor="#fff" stopOpacity="1" />
              </linearGradient>
              <linearGradient id="grad3" x1="0%" x2="0%" y1="0%" y2="100%">
                <stop offset="0%" stopColor="#1D1B4B" stopOpacity="0" />
                <stop offset="100%" stopColor="#1D1B4B" stopOpacity="1" />
              </linearGradient>
            </defs>
            <LoaderLogoBar wide={true} height="42" y="0" rotationNumber={0} />
            <LoaderLogoBar height="17" y="25" rotationNumber={1} />
            <LoaderLogoBar height="17" y="25" rotationNumber={2} />
            <LoaderLogoBar height="17" y="25" rotationNumber={3} />
            <LoaderLogoBar wide={true} height="42" y="0" rotationNumber={4} />
            <LoaderLogoBar height="22" y="20" rotationNumber={5} />
            <LoaderLogoBar height="17" y="25" rotationNumber={6} />
            <LoaderLogoBar height="32" y="10" rotationNumber={7} />

            <LoaderLogoBar
              height="22"
              y="15"
              goingOut={true}
              rotationNumber={0}
            />
            <LoaderLogoBar
              height="27"
              y="10"
              goingOut={true}
              rotationNumber={1}
            />
            <LoaderLogoBar
              height="22"
              y="15"
              goingOut={true}
              rotationNumber={2}
            />
            <LoaderLogoBar
              wide={true}
              height="32"
              y="5"
              goingOut={true}
              rotationNumber={3}
            />
            <LoaderLogoBar
              height="32"
              y="5"
              goingOut={true}
              rotationNumber={4}
            />
            <LoaderLogoBar
              height="22"
              y="15"
              goingOut={true}
              rotationNumber={5}
            />
            <LoaderLogoBar
              wide={true}
              height="37"
              y="0"
              goingOut={true}
              rotationNumber={6}
            />
            <LoaderLogoBar
              height="22"
              y="15"
              goingOut={true}
              rotationNumber={7}
            />
          </svg>
        </div>
      )}
    </div>
  );
};
