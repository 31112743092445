import React from 'react';
import { Footer } from '../footer/footer';
import { Header } from '../header/header';
import { Loader } from '../loader/loader';
import { LanquetinArriver } from '../lanquetin-arriver/lanquetin-arriver';

export const Layout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <>
      <LanquetinArriver />
      <Loader />
      <Header />
      {children}
      <Footer />
    </>
  );
};
