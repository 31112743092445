import React from 'react';

import logo from '../../images/logo-terraterre.svg';

import './header.scss';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import { HeaderData } from '../../models/header';

export const Header: React.FC = () => {
  const [scrolled, setScrolled] = React.useState(false);
  const [navOpened, setNavOpened] = React.useState(false);

  const data: { strapiHeader: HeaderData } = useStaticQuery(graphql`
    query {
      strapiHeader {
        links {
          ... on STRAPI__COMPONENT_OTHER_GROUPE_DE_LIEN {
            strapi_component
            name
            links {
              link
              text
            }
          }
          ... on STRAPI__COMPONENT_OTHER_LIEN_UNIQUE {
            strapi_component
            link {
              link
              text
            }
          }
        }
      }
    }
  `);

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const closeNav = () => {
    setNavOpened(false);
  };

  const links = data.strapiHeader.links;

  const urbanismeButton = (inNav = false) => (
    <a
      className={
        'header__urbanisme-button' +
        (inNav ? ' header__urbanisme-button--in-nav' : '')
      }
      href="https://urbanisme.terraterre-ge.fr"
    >
      Espace Urbanisme
      <span className="header__urbanisme-button__icon">
        <FontAwesomeIcon icon={faSignOut} />
      </span>
    </a>
  );

  return (
    <>
      <header
        className={'header section' + (scrolled ? ' header--scrolled' : '')}
      >
        <div className="header__content section__content">
          <div className="header__left">
            <Link to="/">
              <img
                src={logo}
                alt="Logo de Terraterre"
                className="header__logo"
              />
            </Link>
          </div>
          <div className="header__right">
            <div className="header__urbanisme-button-container">
              {urbanismeButton()}
            </div>
            <div
              className={
                'header__burger' + (navOpened ? ' header__burger--opened' : '')
              }
              onClick={() => setNavOpened(!navOpened)}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <nav
          className={'header__nav' + (navOpened ? ' header__nav--opened' : '')}
        >
          <div className="header__nav__content">
            <div className="header__nav__list-container">
              <ul className="header__nav__list">
                {links.map((link, index) => {
                  if (link.strapi_component === 'other.groupe-de-lien') {
                    return (
                      <li key={index} className="header__nav__item">
                        <span className="header__nav__item-title">
                          {link.name}
                        </span>
                        <ul className="header__nav__sublist">
                          {link.links.map((sublink, subindex) => (
                            <li key={subindex} className="header__nav__subitem">
                              <Link onClick={closeNav} to={sublink.link}>
                                {sublink.text}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    );
                  } else {
                    return (
                      <li key={index} className="header__nav__item">
                        <Link onClick={closeNav} to={link.link.link}>
                          {link.link.text}
                        </Link>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>

            <div className="header__nav__urbanisme-button-container">
              {urbanismeButton(true)}
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
