import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import './lanquetin-arriver.scss';

export const LanquetinArriver: React.FC = () => {
  const [display, setDisplay] = React.useState(false);

  React.useEffect(() => {
    if (location.search.includes('fl')) {
      setDisplay(true);
    }
  }, []);

  const close = () => {
    setDisplay(false);
  };

  return (
    <>
      {display && (
        <div className="lanquetin-arriver">
          <div className="lanquetin-arriver__close">
            <FontAwesomeIcon icon={faClose} onClick={close} />
          </div>
          <div className="lanquetin-arriver__content">
            <h3 className="lanquetin-arriver__title">
              Lanquetin et Associés devient Terraterre !
            </h3>
            <p>
              Vous venez d'être redirigé vers notre nouveau site internet. C'est
              maintenant depuis celui-ci que vous pourrez retrouver toutes les
              informations sur notre entreprise ainsi que l'espace urbanisme.
            </p>
          </div>
        </div>
      )}
    </>
  );
};
