import React from 'react';

export interface LoaderLogoBarProps {
  y: string;
  height: string;
  rotationNumber: number;
  wide?: boolean;
  goingOut?: boolean;
}

export const LoaderLogoBar: React.FC<LoaderLogoBarProps> = ({
  wide,
  y,
  height,
  rotationNumber,
  goingOut,
}) => {
  const width = wide ? 4 : 2;
  const rx = wide ? 2 : 1;
  const x = wide ? 48 : 49;

  const rotationDeg = (goingOut ? 11 : 38) + rotationNumber * 45;
  const fill = goingOut ? 'url(#grad1)' : 'url(#grad2)';

  const className = `loader-logo-bar loader-logo-bar--${goingOut ? 'out' : 'in'} loader-logo-bar--${goingOut ? 'out' : 'in'}-${rotationNumber}`;

  return (
    <g transform={`rotate(${rotationDeg} 50 50)`}>
      <rect
        width={width}
        height={height}
        x={x}
        y={y}
        rx={rx}
        fill={fill}
        className={className}
      />
    </g>
  );
};
